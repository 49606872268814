"use strict";Object.defineProperty(exports, "__esModule", {value: true});// src/index.ts






var _core = require('@liveblocks/core');






exports.LiveList = _core.LiveList; exports.LiveMap = _core.LiveMap; exports.LiveObject = _core.LiveObject; exports.createClient = _core.createClient; exports.shallow = _core.shallow;
